* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  color: #34423b;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "PT Sans", sans-serif;

  line-height: 1.15;
}

h1,
.text-size-h1 {
  font-size: 3.3em;
}

h2,
.text-size-h2 {
  font-size: 3em;
}

h3,
.text-size-h3 {
  font-size: 2.2em;
}

h4,
.text-size-h4 {
  font-size: 1.4em;
}

h5,
.text-size-h5 {
  font-size: 1rem;
}

.text-caption {
  font-size: 0.75em;
}

small,
.text-small {
  font-size: 0.56em;
}

p {
  font-weight: 300;

  /* color: #86928d; */
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
